import { useEffect } from "react";
import JobSeekerBase from "job-seeker-flow/dist/pages/JobSeekerBase";
import JSProfileContestDetails from "../../components/JSProfileContestDetails";
import { useAppDispatch } from "../../services/StoreHooks";

const JobSeeker = (props: any) => {
  const { id = 1, contestId = 1 } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch({
      type: "CONTEST_NAVIGATE",
      data: {
        id: id,
        contestId: contestId,
      },
    });
  }, [contestId, dispatch, id]);

  return (
    <div>
      <JSProfileContestDetails />
      <div className="js-profile-js-flow">
        <JobSeekerBase contestId={contestId} id={id} />
      </div>
    </div>
  );
};

export default JobSeeker;
