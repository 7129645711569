import { useEffect, useState } from "react";
import { openPopupWidget } from "react-calendly";
import Header from "../../components/Header";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import MeetTheFounder from "../../components//MeetTheFounder";
import OurClients from "../../components/OurClients";
import SiteMetrics from "../../components/SiteMetrics";
import WhatsInItForRecrutiers from "../../components/WhatsInItForRecruiters";
import TitleMultiColor from "../../components/TitleMultiColor";
import HiringhoodVsOthers from "../../components/HiringhoodVsOthers";
import DrivingImpact from "../../components/DrivingImpact";
import WhyHiringhood from "../../components/WhyHiringhood";
import WhatCustomersAreSaying from "../../components/WhatCustomersAreSaying";
import BookACall from "../../components/BookACall";
import OurPartners from "../../components/OurPartners";
import BackgroundImage from "../../assets/login-background.svg";
import HomePageFAQ from "../../components/HomePageFAQ";
import { useAppDispatch } from "../../services/StoreHooks";
import "./styles.scss";

const Home = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch({
      type: "SET_ACTIVE_LINK",
      data: {
        activeLink: "Home",
      },
    });
  }, [dispatch]);

  const handleScheduleDemo = () => {
    return openPopupWidget({
      url: process.env.REACT_APP_SCHEDULE_DEMO_URL ?? "",
    });
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
      const handleWindowResize = () => {
          setWindowWidth(window.innerWidth);
      };
      
      window.addEventListener('resize', handleWindowResize);

      return () => {
          window.removeEventListener('resize', handleWindowResize);
      }
  }, []);

  return (
    <div className="wrapper home-container">
      <div className="home-wrapper">
        <div className="home-banner">
          <Header />
          <p className="home-harness-text">
            Harness The Power Of The Crowd To Fill Job Openings
          </p>
          <div className="home-buttons">
            <Button
              variant="primary"
              label="Schedule a Demo"
              onClick={handleScheduleDemo}
            />
          </div>
        </div>

        <div className="home-our-clients-container">
          <OurClients />
        </div>
        <div className="home-our-how-hiringhood-works-container">
          <TitleMultiColor
            labels={["How", "Hiringhood", "Works"]}
            colors={["#626880", "#4D6CD9", "#626880"]}
          />
          <video
            height="auto"
            controls
            controlsList="nodownload"
            autoPlay
            loop
            muted
          >
            <source
              src={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}hiringhood.mp4`}
            />
          </video>
        </div>
        <div className="home-site-metrics">
          <TitleMultiColor
            labels={["Site", "Metrics"]}
            colors={["#626880", "#4D6CD9"]}
          />
          <SiteMetrics />
        </div>
        <div className="home-whats-in-it">
          <TitleMultiColor
            labels={["What’s In It For", "Recruiters", "?"]}
            colors={["#FFFFFF", "#00C15D", "#FFFFFF"]}
          />
          <WhatsInItForRecrutiers />
        </div>
        <div className="home-driving-impact">
          <TitleMultiColor
            labels={["Driving Impact", "For Our Customers"]}
            colors={["#4D6CD9", "#626880"]}
          />
          <DrivingImpact />
        </div>
        <div className="home-hiringhood-vs-others">
          <TitleMultiColor
            labels={["Hiringhood", "Vs Others"]}
            colors={["#4D6CD9", "#626880"]}
          />
          <HiringhoodVsOthers />
        </div>
        <div className="home-why-hiringhood">
          <TitleMultiColor
            labels={["Why", "Hiringhood"]}
            colors={["#626880", "#4D6CD9"]}
          />
          <WhyHiringhood />
        </div>
        <div className="home-meet-the-founder-container">
          <MeetTheFounder />
        </div>
        <div className="home-what-customers-are-saying">
          <TitleMultiColor
            labels={["What Our", "Customers Are Saying"]}
            colors={["#626880", "#4D6CD9"]}
          />
          <div
            style={{
              backgroundImage: `url(${BackgroundImage})`,
              backgroundSize: "cover",
              height: "28.6875rem",
              width: "-webkit-fill-available",
            }}
          >
            <WhatCustomersAreSaying />
          </div>
        </div>
        <div className="home-partners-container">
          <OurPartners />
        </div>
        <div className="home-lets-chat">
          <BookACall />
        </div>
        {/* <div className="home-blogs-container">
          <Blogs title="Blogs" />
        </div> */}
        <div className="home-page-faq">
          <HomePageFAQ />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
