import employer from "../../assets/employer.png";
import rewards from "../../assets/rewards.png";
import users from "../../assets/users.svg";
import experience from "../../assets/experience.svg";
import location from "../../assets/location.svg";
import history from "../../assets/history.svg";
import "./styles.css";

const JSProfileContestDetails = () => {
  return (
    <div className="js-profile-contest-details">
      <div className="js-profile-contest-details-section-1">
        <p className="js-profile-contest-details-badge">Most Wanted</p>
        <p className="js-profile-contest-details-type">Alive & Full Time</p>
        <p className="js-profile-contest-details-section-1-text">
          Bounty:{" "}
          <span className="js-profile-contest-details-section-1-text-value">
            ₹8,96,400
          </span>
        </p>
        <p className="js-profile-contest-details-section-1-text">
          Location:{" "}
          <span className="js-profile-contest-details-section-1-text-value">
            Hyderabad
          </span>
        </p>
        <p className="js-profile-contest-details-section-1-text">
          Max. Notice Period:
          <span className="js-profile-contest-details-section-1-text-value">
            30 days
          </span>
        </p>
        <p className="js-profile-contest-details-section-1-text">
          Contest Status:
          <span className="js-profile-contest-details-section-1-text-value">
            Active
          </span>
        </p>
      </div>
      <div className="js-profile-contest-details-section-2">
        <p className="js-profile-contest-details-contest-name">
          L2 Security Analyst
        </p>
        <div className="js-profile-contest-details-icons-container">
          <div className="js-profile-contest-details-icon-container">
            <img src={employer} alt="Employer" />
            <p className="js-profile-contest-details-icon-text">ATMECS</p>
          </div>
          <div className="js-profile-contest-details-icon-container">
            <img src={rewards} alt="Cash" />
            <p className="js-profile-contest-details-icon-text">9-17 Lakhs</p>
          </div>
        </div>
        <div className="js-profile-contest-details-icons-container">
          <div className="js-profile-contest-details-icon-container">
            <img src={users} alt="Positions" />
            <p className="js-profile-contest-details-icon-text">3 Positions</p>
          </div>
          <div className="js-profile-contest-details-icon-container">
            <img src={experience} alt="Experience" />
            <p className="js-profile-contest-details-icon-text">4-7 Years</p>
          </div>
        </div>
        <div className="js-profile-contest-details-icon-container">
          <img src={location} alt="Location" />
          <p className="js-profile-contest-details-icon-text">
            ATMECS Technologies Pvt. Limited
          </p>
        </div>
        <div className="js-profile-contest-details-icon-container">
          <img src={history} alt="History" />
          <p className="js-profile-contest-details-icon-text">3 months</p>
        </div>
      </div>
    </div>
  );
};

export default JSProfileContestDetails;
