import OnboardBase from "./pages/OnboardBase";
import Signup from "./pages/Signup/Signup";
import JSProfile from "./pages/JSProfile";
import { Route, Routes } from "react-router-dom";
import tawkTo from "tawkto-react";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import StoreService from "./services/StoreService";
import { ProtectedRoute } from "./components/ProtectedRoute";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";

import { useEffect } from "react";

const store = StoreService;

const tawkToPropertyId = process.env.REACT_APP_TAWK_TO_PROP_ID;
const tawkToKey = process.env.REACT_APP_TAWK_TO_KEY;

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

function App() {
  useEffect(() => {
    tawkTo(tawkToPropertyId, tawkToKey);
    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {}, []);

  console.log("got into app component for routing");
  return (
    <Provider store={store}>
      <Routes>
        <Route
          path="/signup"
          element={
            <ProtectedRoute>
              <Signup />
            </ProtectedRoute>
          }
        />

        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/" element={<Home />} />
        <Route path="/js" element={<JSProfile />} />
        <Route
          path="/onboard"
          element={
            <ProtectedRoute>
              <OnboardBase />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Provider>
  );
}

export default App;
