export const HOME = "/";
export const CONTESTS = "/contests";
export const CONTEST_DETAILS = "/contest/:id";
export const LOGIN = "/login";
export const ABOUT_US = "/aboutUs";
export const ON_BOARDING = "/onboarding";
export const EDIT_PROFILE = "/editProfile";
export const SIGNUP = "/signup";
export const RECRUITERS = "/recruiters";
export const RECRUITER_DETAILS = "/recruiter/:id";
export const TERMS_OF_USE = "/termsOfUse";
export const PRIVACY_POLICY = "/privacyPolicy";
export const FAQ = "/faq";
export const EMPLOYERS = "/employers";
export const EMPLOYER_DETAILS = "/employer/:id";
export const PARTNERS = "/partners";
export const PARTNER_DETAILS = "/partner/:id";
export const LOGOUT = "/logout";
export const HOTLIST = "/hotlist";
