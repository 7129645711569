import { combineReducers } from "redux";
import currentUser from "./userState";
import adminContest from "./adminContest";
import menuState from "./menuState";

export default combineReducers({
  currentUser,
  adminContest,
  menuState,
});
